import React from "react";
import "./provas-anteriores.css";

const ProvasAnteriores = () => {
  return (
    <div className="container provas-container" id="provas-home">
      <div className="provas-header">
        <p>
          <a href="/">Home</a> <font color="#141414">/</font> Provas Anteriores
        </p>
      </div>
      <div className="provas-content">
        <div className="provas-title">
          <p>Concurso Canguru</p>
          <h5>Provas Anteriores</h5>
        </div>
        <div className="provas-recente">
          <p>Mais recente</p>
          <div className="btn btn-provas-recente">
            <a href="/provas-anteriores-2024">2024</a>
          </div>
        </div>
        <div className="provas-anteriores">
          <p>Anos anteriores</p>
          <div className="provas-anteriores-buttons">
            <div className="btn btn-provas-2023">
              <a href="/provas-anteriores-2023">2023</a>
            </div>
            <div className="btn btn-provas-2022">
              <a href="/provas-anteriores-2022">2022</a>
            </div>
            <div className="btn btn-provas-2021">
              <a href="/provas-anteriores-2021">2021</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvasAnteriores;
