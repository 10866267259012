import React from "react";

const Provas2023 = () => {
  return (
    <div className="container provas2023-container" id="provas-2023">
      <div className="provas-header">
        <p>
          <a href="/">Home</a> <font color="#141414">/</font>{" "}
          <a href="/provas-anteriores">Provas Anteriores</a>{" "}
          <font color="#141414">/</font> 2023
        </p>
      </div>
      <div className="provas-content">
        <div className="provas-title">
          <p>Provas e Resoluções 2023</p>
          <h5>Online e Presencial</h5>
        </div>
        <div className="btn btn-corte">
          <a
            href="https://canguru-de-matematica.s3.amazonaws.com/Nota_de_Corte_2023.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Nota de corte
          </a>
        </div>
        <div className="provas2023-buttons">
          <div className="provas-p">
            <div className="provas-txt">
              <p>Prova Nível P (Pre-Ecolier) | </p>
              <small>3º e 4º anos do EFI</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Provas/2023/Prova+N%C3%ADvel+P+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Ingl%C3%AAs/2023/PreEcolier+ingl%C3%AAs+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
              <div className="btn btn-gabarito">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Gabarito/2023/Gabarito+N%C3%ADvel+P+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Gabarito
                </a>
              </div>
            </div>
          </div>
          <div className="provas-e">
            <div className="provas-txt">
              <p>Prova Nível E (Ecolier) | </p>
              <small>5º e 6º anos do EFI e EFII, respectivamente</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Provas/2023/Prova+N%C3%ADvel+E+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Ingl%C3%AAs/2023/Ecolier+ingl%C3%AAs+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
              <div className="btn btn-gabarito">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Gabarito/2023/Gabarito+N%C3%ADvel+E+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Gabarito
                </a>
              </div>
            </div>
          </div>
          <div className="provas-b">
            <div className="provas-txt">
              <p>Prova Nível B (Benjamin) | </p>
              <small>7º e 8º anos do EFII</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Provas/2023/Prova+N%C3%ADvel+B+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Ingl%C3%AAs/2023/Benjamin+ingl%C3%AAs+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
              <div className="btn btn-gabarito">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Gabarito/2023/Gabarito+N%C3%ADvel+B+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Gabarito
                </a>
              </div>
            </div>
          </div>
          <div className="provas-c">
            <div className="provas-txt">
              <p>Prova Nível C (Cadet) |</p>
              <small>9º ano do EFII</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Provas/2023/Prova+N%C3%ADvel+C+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Ingl%C3%AAs/2023/Cadet+ingl%C3%AAs+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
              <div className="btn btn-gabarito">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Gabarito/2023/Gabarito+N%C3%ADvel+C+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Gabarito
                </a>
              </div>
            </div>
          </div>
          <div className="provas-j">
            <div className="provas-txt">
              <p>Prova Nível J (Junior) | </p>
              <small>1ª e 2ª séries do EM</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Provas/2023/Prova+N%C3%ADvel+J+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Ingl%C3%AAs/2023/Junior+ingl%C3%AAs+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
              <div className="btn btn-gabarito">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Gabarito/2023/Gabarito+N%C3%ADvel+J+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Gabarito
                </a>
              </div>
            </div>
          </div>
          <div className="provas-s">
            <div className="provas-txt">
              <p>Prova Nível S (Student) | </p>
              <small>3ª série do EM</small>
            </div>
            <div className="prova-buttons">
              <div className="btn btn-prova">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Provas/2023/Prova+N%C3%ADvel+S+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova
                </a>
              </div>
              <div className="btn btn-prova-ingles">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Ingl%C3%AAs/2023/Student+ingl%C3%AAs+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prova <b>em inglês</b>
                </a>
              </div>
              <div className="btn btn-gabarito">
                <a
                  href="https://canguru-de-matematica.s3.amazonaws.com/Gabarito/2023/Gabarito+N%C3%ADvel+S+2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Gabarito
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Provas2023;
