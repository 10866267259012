import React from "react";
import "./navbar.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import LOGOCANGURU from "../../assets/img/navbar/logo.svg";

const Navbar = () => {
  const location = useLocation();

  const scrollToSection = (sectionId) => (event) => {
    event.preventDefault();

    if (location.pathname === "/") {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.location.href = `/#${sectionId}`;
    }
  };

  return (
    <header id="navbar">
      <nav className="container navbar-container">
        <div className="navbar-top">
          <div className="navbar-img">
            <Link to="/" id="mobile-img">
              <img src={LOGOCANGURU} alt="Canguru de Matemática Brasil" />
            </Link>

            <Link to="/" id="desk-img">
              <img src={LOGOCANGURU} alt="Canguru de Matemática Brasil" />
            </Link>
          </div>
          <div className="navbar-btns">
            <div className="btn navbar-btn-estudante">
              <a
                href="https://aluno.cangurudematematicabrasil.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                Área do Estudante
              </a>
            </div>

            <div className="btn navbar-btn-escola">
              <a
                href="https://gestao.upmat.com.br/auth"
                target="_blank"
                rel="noreferrer"
              >
                Área da Escola
              </a>
            </div>
          </div>
        </div>

        <div className="navbar-links">
          <div className="navbar-sobre">
            <a href="#sobre" onClick={scrollToSection("desafio")}>
              Sobre
            </a>
          </div>

          <div className="navbar-inscricoes">
            <a href="#inscricoes" onClick={scrollToSection("inscricoes")}>
              Inscrições
            </a>
          </div>

          <div className="navbar-cronograma">
            <NavLink to={"/cronograma"}>
              <p>Cronograma</p>
            </NavLink>
          </div>

          <div className="navbar-regulamento">
            <NavLink to={"/regulamento"}>
              <p>Regulamento</p>
            </NavLink>
          </div>

          <div className="navbar-comunidade">
            <NavLink to={"/comunidade"}>Comunidade</NavLink>
          </div>

          <div className="navbar-provas">
            <NavLink to={"/provas-anteriores"}>
              <p>Provas Anteriores</p>
            </NavLink>
          </div>

          <div className="navbar-faq">
            <NavLink to={"/faq"}>FAQ</NavLink>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
