import React from "react";
import "./depoimentos.css";
import DepoimentosCarousel from "../depoimentoscarousel/DepoimentosCarousel";

const Depoimentos = () => {
  return (
    <section id="depoimentos">
      <div className="container depoimentos-container">
        <div className="depoimentos-txt">
          <h4>Depoimentos</h4>
          <h3>O que aprendemos com o Canguru</h3>
        </div>

        <div className="depoimentos-carousel">
          <DepoimentosCarousel />
        </div>
      </div>
    </section>
  );
};

export default Depoimentos;
