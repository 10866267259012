import React from "react";
import "./programatico.css";

const Programatico = () => {
  return (
    <div className="conteudo-programatico-txt">
      <h5>
        Os conteúdos são cumulativos. A cada nível os novos temas são
        acrescentados na lista abaixo:
      </h5>
      <ul id="conteudo-programatico">
        <li id="nivelP">
          <p>NÍVEL P - Alunos do 3 º e 4 º Anos EFI:</p>
          <ul className="lista-programatico">
            <li>
              <small>
                Números naturais: contagem, ordenação, sistema de numeração.
              </small>
            </li>
            <li>
              <small>
                Adição e subtração de números naturais com até dois algarismos.
              </small>
            </li>
            <li>
              <small>
                Multiplicação de números naturais com até dois algarismos no
                multiplicando.
              </small>
            </li>
            <li>
              <small>
                Divisão de números naturais com resto zero e divisor de um
                algarismo.
              </small>
            </li>
            <li>
              <small>
                Conceitos básicos de múltiplos e divisores: dobro, triplo,
                metade, um terço.
              </small>
            </li>
            <li>
              <small>Ordenação de números, letras e figuras.</small>
            </li>
            <li>
              <small>
                Reconhecimento de formas geométricas simples (triângulo,
                quadrado, retângulo).
              </small>
            </li>
            <li>
              <small>Reconhecimento de padrões em figuras.</small>
            </li>
            <li>
              <small>Reconhecimento de padrões em sequências de figuras.</small>
            </li>
            <li>
              <small>Contagem de números e figuras.</small>
            </li>
            <li>
              <small>
                Movimentos simples de figuras (translação, reflexão).
              </small>
            </li>
            <li>
              <small>
                Relógio analógico e digital: horas e minutos, operações simples
                com horas.
              </small>
            </li>
            <li>
              <small>
                Número de dias em uma semana, número de meses em um ano.
              </small>
            </li>
            <li>
              <small>
                Medidas lineares e de massa mais usuais (km, m, cm) e (kg, g).
              </small>
            </li>
            <li>
              <small>
                Localização no plano e no espaço: esquerda, direita, acima,
                abaixo, fora, dentro, atrás, etc.
              </small>
            </li>
            <li>
              <small>
                Pré-álgebra com valores atribuídos a figuras, geométricas ou
                não.
              </small>
            </li>
            <li>
              <small>Contagem básica de caminhos.</small>
            </li>
            <li>
              <small>
                Figuras espaciais simples: cubos, blocos retangulares,
                pirâmides.
              </small>
            </li>
            <li>
              <small>
                Composições de figuras planas e espaciais a partir de figuras
                menores.
              </small>
            </li>
            <li>
              <small>
                Problemas envolvendo a compreensão de textos simples.
              </small>
            </li>
            <li>
              <small>Problemas envolvendo lógica e estratégia.</small>
            </li>
          </ul>
        </li>
        <br />
        <br />

        <li id="nivelE">
          <p>NÍVEL E - Alunos do 5 º e 6 º Anos EFI/EFII</p>
          <small>Conteúdo anterior mais:</small>
          <ul className="lista-programatico">
            <li>
              <small>
                Operações aritméticas básicas com números naturais de até quatro
                algarismos (adição e subtração); multiplicação com multiplicador
                de até dois algarismos. Divisão euclidiana (dividendo, divisor,
                quociente, resto) com divisor de um algarismo.
              </small>
            </li>
            <li>
              <small>Padrões em sequências de figuras, números e letras.</small>
            </li>
            <li>
              <small>
                Codificação simples envolvendo letras, números e figuras.
              </small>
            </li>
            <li>
              <small>
                Correspondência entre variáveis e figuras. Uso simples das
                propriedades das igualdades (reflexiva, simétrica, transitiva,
                multiplicativa e aditiva).
              </small>
            </li>
            <li>
              <small>
                Figuras geométricas e algumas de suas propriedades: triângulos,
                quadriláteros e hexágonos.
              </small>
            </li>
            <li>
              <small>
                Transformações simples de figuras geométricas ou figuras
                naturais: translação, reflexão e rotação.
              </small>
            </li>
            <li>
              <small>
                Reconhecimento da invariância de elementos em situações
                envolvendo transformações de figuras.
              </small>
            </li>
            <li>
              <small>União e Intersecção de conjuntos.</small>
            </li>
            <li>
              <small>Reconhecimento de padrões em sequências de figuras.</small>
            </li>
            <li>
              <small>Contagem de números e figuras.</small>
            </li>
            <li>
              <small>
                Contagem em situações envolvendo listagem organizada ou os
                princípios multiplicativo ou aditivo básicos.
              </small>
            </li>
            <li>
              <small>
                Medidas lineares (perímetros) e de área: quadrados e retângulos.
              </small>
            </li>
            <li>
              <small>
                Composição e decomposição de figuras geométricas, planas ou
                espaciais.
              </small>
            </li>
            <li>
              <small>
                Raciocínio lógico simples envolvendo implicação e negação em
                problemas verbais.
              </small>
            </li>
            <li>
              <small>
                Problemas numéricos ou geométricos com quadriculados.
              </small>
            </li>
            <li>
              <small>Problemas com relógios digitais e analógicos.</small>
            </li>
            <li>
              <small>Problemas envolvendo a pré-álgebra.</small>
            </li>
            <li>
              <small>Problemas de máximos e mínimos elementares.</small>
            </li>
            <li>
              <small>
                Problemas envolvendo equilíbrio de corpos (balanças, móbiles,
                etc.).
              </small>
            </li>
          </ul>
        </li>
        <br />
        <br />

        <li id="nivelB">
          <p>NÍVEL B - Alunos que 7 º e 8 º Anos EFII:</p>
          <small>Conteúdo anterior mais:</small>
          <ul className="lista-programatico">
            <li>
              <small>Operações com números inteiros e os sinais.</small>
            </li>
            <li>
              <small>
                Adição e subtração sem restrições. Multiplicação por números de
                dois algarismos. Divisões exatas por números de até dois
                algarismos.
              </small>
            </li>
            <li>
              <small>
                Divisão euclidiana e divisibilidade, com divisores positivos.
              </small>
            </li>
            <li>
              <small>
                Correspondência entre variáveis e figuras. Uso simples das
                propriedades das igualdades (reflexiva, simétrica, transitiva,
                multiplicativa e aditiva).
              </small>
            </li>
            <li>
              <small>Frações e correspondência com a divisão.</small>
            </li>
            <li>
              <small>Porcentagens</small>
            </li>
            <li>
              <small>
                Sequências numéricas mais complexas (recorrência ou fórmulas).
              </small>
            </li>
            <li>
              <small>
                Expressões aritméticas envolvendo as operações elementares e
                potenciação.
              </small>
            </li>
            <li>
              <small>
                Geometria plana: ngulos em triângulos, relações entre elementos
                simples das figuras planas.
              </small>
            </li>
            <li>
              <small>
                Decomposições de cubos, planificações de cubos e blocos
                retangulares.
              </small>
            </li>
            <li>
              <small>
                Problemas de lógica em tabuleiro. Quadrados mágicos.
              </small>
            </li>
            <li>
              <small>Problemas de lógica formal ou verbal.</small>
            </li>
            <li>
              <small>Números inteiros e racionais na reta.</small>
            </li>
            <li>
              <small>
                Contagem: combinando os princípios multiplicativo e aditivo.
              </small>
            </li>
            <li>
              <small>Princípio da casa dos pombos.</small>
            </li>
          </ul>
        </li>
        <br />
        <br />

        <li id="nivelC">
          <p>NÍVEL C - Alunos do 9 º ano EFII:</p>
          <small>Conteúdo anterior mais:</small>
          <ul className="lista-programatico">
            <li>
              <small>Propriedades de números: sistema de numeração.</small>
            </li>
            <li>
              <small>Operações com números racionais.</small>
            </li>
            <li>
              <small>Potência de números naturais.</small>
            </li>
            <li>
              <small>Razões, proporções.</small>
            </li>
            <li>
              <small>
                Relações e medidas de ângulos em figuras geométricas planas.
              </small>
            </li>
            <li>
              <small>Área de retângulos, triângulos e círculos.</small>
            </li>
            <li>
              <small>
                Relações entre elementos de figuras geométricas (polígonos
                convexos).
              </small>
            </li>
            <li>
              <small>Transformações geométricas e problemas.</small>
            </li>
            <li>
              <small>
                Equações, desigualdades e sistemas de equações lineares.
              </small>
            </li>
            <li>
              <small>
                Contagem: combinações simples. Aplicações numéricas e
                geométricas.
              </small>
            </li>
            <li>
              <small>
                Interpretação de dados e reconhecimento de algoritmos.
              </small>
            </li>
            <li>
              <small>Pontos no plano cartesiano. Equação da reta.</small>
            </li>
          </ul>
        </li>
        <br />
        <br />

        <li id="nivelJ">
          <p>NÍVEL J - Alunos da 1 ª e 2 ª série EM:</p>
          <small>Conteúdo anterior mais:</small>
          <ul className="lista-programatico">
            <li>
              <small>Operações com números reais.</small>
            </li>
            <li>
              <small>
                Funções: propriedades, gráficos, equações funcionais.
              </small>
            </li>
            <li>
              <small>Polinômios de uma variável.</small>
            </li>
            <li>
              <small>
                Sequências numéricas e fórmulas de recorrência. Princípio da
                indução.
              </small>
            </li>
            <li>
              <small>Contagem: combinações com repetições.</small>
            </li>
            <li>
              <small>Geometria euclidiana plana geral.</small>
            </li>
            <li>
              <small>Geometria analítica plana.</small>
            </li>
          </ul>
        </li>
        <br />
        <br />

        <li id="nivelS">
          <p>NÍVEL S - Alunos da 3 ª série EM:</p>
          <small>Conteúdo anterior mais:</small>
          <ul className="lista-programatico">
            <li>
              <small>Geometria euclidiana plana e espacial.</small>
            </li>
            <li>
              <small>Geometria analítica espacial.</small>
            </li>
            <li>
              <small>Trigonometria aplicada à geometria.</small>
            </li>
            <li>
              <small>Combinatória geral.</small>
            </li>
            <li>
              <small>Probabilidade.</small>
            </li>
            <li>
              <small>Lógica matemática e problemas de lógica.</small>
            </li>
            <li>
              <small>Equações algébricas.</small>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Programatico;
