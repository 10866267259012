import React, { useState } from "react";
import "./duvidas.css";
import { PiPlusCircleFill, PiMinusCircleFill } from "react-icons/pi";

const data = [
  {
    title: "O que é o Concurso Canguru?",
    text: (
      <div className="inside-txt">
        <p>
          O Concurso Canguru de Matemática Brasil é uma competição anual
          internacional, destinada aos alunos do 3º ano do Ensino Fundamental
          até a 3ª série do Ensino Médio
        </p>
        <br />
        <p>
          A competição teve origem na França e é administrada globalmente pela
          Associação Canguru sem Fronteiras (Association Kangourou sans
          Frontières - AKSF).
        </p>
        <br />
        <p>
          O Concurso Canguru de Matemática é a maior competição de Matemática do
          mundo, com mais de 6 milhões de participantes por ano, de 75 países.
        </p>
        <br />
        <p>Os objetivos do CCMB são:</p>
        <br />
        <p>- Ampliar e incentivar os conhecimentos matemáticos</p>
        <br />
        <p>
          - Contribuir para a melhoria do ensino de Matemática em todos os
          níveis;
        </p>
        <br />
        <p>
          - Favorecer o estudo de maneira interessante e contextualizada,
          aproximando os alunos do universo da Matemática;
        </p>
        <br />
        <p>
          - Desenvolver a capacidade dos alunos de obter prazer e satisfação
          através da vida intelectual.
        </p>
      </div>
    ),
  },
  {
    title: "Quem pode participar?",
    text: (
      <div className="inside-txt">
        <p>
          Podem participar estudantes das escolas públicas e privadas
          brasileiras matriculados regularmente desde o 3º ano do Ensino
          Fundamental até a 3ª Série do Ensino Médio. As inscrições para o
          Concurso devem ser realizadas pelas escolas.
        </p>
      </div>
    ),
  },
  {
    title: "Como posso fazer a inscrição da minha escola?",
    text: (
      <div className="inside-txt">
        <p>
          A inscrição é feita por meio da escola que utilizará, para
          identificação durante todo o processo, seu código do MEC/INEP de 8
          dígitos (sem utilizar 0). As escolas com mais de uma filial, com
          códigos MEC/INEP diferentes, deverão inscrever-se por código e não por
          rede.
        </p>
        <br />
        <p>
          As escolas privadas conveniadas com a rede pública de ensino que
          tiverem composição mista (alunos da rede pública e privada) serão
          consideradas como privadas em todas as etapas do processo.
        </p>
        <br />
        <p>
          Não são permitidas inscrições de pessoas físicas, isto é, um estudante
          ou grupo de estudantes e/ou pais e familiares não podem fazer
          inscrição sem a intermediação das escolas.
        </p>
        <br />
        <p>
          Para realizar a inscrição não é necessário fornecer a quantidade e os
          dados dos alunos que realizarão as provas.
        </p>
      </div>
    ),
  },
  {
    title: "Como as provas estão organizadas?",
    text: (
      <div className="inside-txt">
        <p>
          As provas possuem questões objetivas (testes) com cinco respostas
          alternativas, sendo apenas uma a correta.
        </p>
        <br />
        <p>Há seis níveis de provas:</p>
        <br />
        <p>nível P – alunos do 3º e 4º anos do EFI</p>
        <br />
        <p>nível E – alunos do 5º e 6º anos do EFI e EFII, respectivamente</p>
        <br />
        <p>nível B – alunos do 7º e 8º anos do EFII</p>
        <br />
        <p>nível C – alunos do 9º ano do EFII</p>
        <br />
        <p>nível J – alunos da 1ª e 2ª séries do EM</p>
        <br />
        <p>nível S – alunos da 3ª série do EM</p>
        <br />
        <p>Duração máxima: 1h40min para todos os níveis</p>
      </div>
    ),
  },
  {
    title: "Como saber o resultado?",
    text: (
      <div className="inside-txt">
        <p>
          A plataforma do Concurso Canguru de Matemática Brasil irá fazer a
          correção das respostas enviadas e fornecerá, às escolas participantes,
          os resultados preliminares de seus respectivos alunos, divididos nas
          seguintes categorias: alunos com potencial de premiação e alunos
          participantes
        </p>
        <br />
        <p>
          Escolas que optaram pelo modo Presencial com alunos com potencial de
          premiação receberão um aviso para que enviem para a organização do
          Canguru as imagens escaneadas das folhas de respostas originais desses
          alunos para a devida autenticação, caso ainda não o tenham feito.
          Atenção! O envio das folhas de respostas dos alunos com potencial de
          premiação será realizado através de formulário online específico que
          será disponibilizado na área reservada do site Canguru. O não envio
          das folhas de resposta dos alunos com possibilidade de premiação
          impedirá que a escola adquira medalhas para os seus alunos premiados.
        </p>
      </div>
    ),
  },
  {
    title: "Qual a premiação para quem participar?",
    text: (
      <div className="inside-txt">
        <p>
          Todos os alunos que realizaram a prova do Concurso Canguru de
          Matemática Brasil, obtendo status de premiação ou não, terão direito a
          um Certificado gratuito de participação no Concurso. Os Certificados
          serão disponibilizados para as Escolas e elas realizarão a entrega aos
          seus alunos.
        </p>
        <br />
        <p>
          *As medalhas são itens opcionais, o que significa que a escola pode
          optar por adquiri-las ou não. Além disso, a aquisição está restrita à
          escola e não está disponível para compra por parte dos familiares dos
          participantes.
        </p>
      </div>
    ),
  },
  {
    title: "Qual é o conteúdo programático da prova?",
    text: (
      <div className="inside-txt">
        <p>
          <a
            href="/conteudo-programatico"
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#231f20",
            }}
          >
            Clique aqui
          </a>{" "}
          e acesse o conteúdo programático completo.
        </p>
      </div>
    ),
  },
];

const AccordionItem = ({ item, isExpanded, toggleAccordion }) => {
  return (
    <div className="accordion">
      <div
        className={`accordion-header ${isExpanded ? "expanded" : ""}`}
        onClick={toggleAccordion}
      >
        {item.title}

        {isExpanded ? (
          <PiMinusCircleFill size={22} color="#009DDC" />
        ) : (
          <PiPlusCircleFill size={22} color="#009DDC" />
        )}
      </div>
      {isExpanded && (
        <div className="accordion-content">
          <p>{item.text}</p>
        </div>
      )}
    </div>
  );
};

const Duvidas = () => {
  const [expandedItems, setExpandedItems] = useState(
    new Array(data.length).fill(false)
  );

  const toggleAccordion = (index) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.map((item, i) => (i === index ? !item : false))
    );
  };

  return (
    <section id="duvidas">
      <div className="container duvidas-container">
        <div className="duvidas-txt">
          <h4>Dúvidas</h4>
          <p>Perguntas frequentes</p>
        </div>

        {data.map((item, index) => (
          <AccordionItem
            key={index}
            item={item}
            isExpanded={expandedItems[index]}
            toggleAccordion={() => toggleAccordion(index)}
          />
        ))}
      </div>
    </section>
  );
};

export default Duvidas;
