import React from "react";
import "./comunidades.css";
import ComunidadeTop from "../../components/comunidade/comunidadetop/ComunidadeTop";
import ComunidadeBottom from "../../components/comunidade/comunidadeBottom/ComunidadeBottom";

const Comunidades = () => {
  return (
    <>
      <div className="container comunidades-container" id="comunidades-home">
        <div className="comunidades-header">
          <p>
            <a href="/">Home</a> <font color="#141414">/</font> Comunidade
            Internacional
          </p>
        </div>
        <div className="comunidades-content">
          <div className="comunidades-title">
            <p>Concurso Canguru</p>
            <h5>Comunidade Internacional</h5>
          </div>
        </div>
      </div>

      <ComunidadeTop />
      <ComunidadeBottom />
    </>
  );
};

export default Comunidades;
