import React from "react";
import "./cronograma.css";

import WEB from "../../assets/img/cronograma/cronograma-web.svg";
import MOBILE from "../../assets/img/cronograma/cronograma-web.svg";

const Cronograma = () => {
  return (
    <div className="container cronograma-container">
      <div className="cronograma-header">
        <p>
          <a href="/">Home</a> <font color="#141414">/</font> Cronograma
        </p>
      </div>
      <div className="cronograma-content">
        <div className="cronograma-title">
          <p>Concurso Canguru</p>
          <h5>Cronograma</h5>
        </div>
        <div className="cronograma-mobile">
          <img src={MOBILE} alt="" />
        </div>
        <div className="cronograma-web">
          <img src={WEB} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Cronograma;
