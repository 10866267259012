import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTopOnMount from "./components/scroll/ScrollToTopOnMount";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Cronograma from "./pages/cronograma/Cronograma";
import Regulamentos from "./pages/regulamentos/Regulamentos";
import ProvasAnteriores from "./pages/provas-anteriores/ProvasAnteriores";
import Provas2024 from "./pages/provas-anteriores/Provas2024";
import Provas2023 from "./pages/provas-anteriores/Provas2023";
import Provas2022 from "./pages/provas-anteriores/Provas2022";
import Provas2021 from "./pages/provas-anteriores/Provas2021";
import Footer from "./components/footer/Footer";
import ConteudoProgramatico from "./pages/regulamentos/ConteudoProgramatico";
import Comunidades from "./pages/comunidade/Comunidades";
import Faq from "./pages/faq/faq";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTopOnMount />
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="cronograma" element={<Cronograma />} />
        <Route path="regulamento" element={<Regulamentos />} />
        <Route path="conteudo-programatico" element={<ConteudoProgramatico />} />
        <Route path="comunidade" element={<Comunidades />} />
        <Route path="provas-anteriores" element={<ProvasAnteriores />} />
        <Route path="provas-anteriores-2024" element={<Provas2024 />} />
        <Route path="provas-anteriores-2023" element={<Provas2023 />} />
        <Route path="provas-anteriores-2022" element={<Provas2022 />} />
        <Route path="provas-anteriores-2021" element={<Provas2021 />} />
        <Route path="faq" element={<Faq />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
