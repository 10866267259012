import React, { useState } from "react";
import "./depoimentos-carousel.css";
import { PiQuotesFill } from "react-icons/pi";

const slideData = [
  {
    text: "Participar do Canguru e o reconhecimento que a Olimpíada me trouxe incentivaram-me a continuar estudando, preparando-me para as olimpíadas científicas. Já faço o Canguru há sete anos e é uma prova que eu amo, foi o pontapé inicial para que eu participasse de olimpíadas.",
    name: "Luísa Lanza",
    ocupation: "Medalhista",
  },
  {
    text: "Sou apaixonada por matemática e este ano foi a primeira vez que participei da Olimpíada Canguru. Foi uma experiência incrível e eu fiquei muito feliz com o meu resultado. Essa conquista para mim foi muito importante, porque foi resultado do meu esforço e me fez perceber que eu posso ir muito além.",
    name: "Mariana Marinho",
    ocupation: "Medalhista",
  },
  {
    text: "Ter participado do Canguru foi excepcional. Martin permaneceu motivado a desenvolver conhecimentos matemáticos, aflorando a curiosidade e incentivando os desafios.",
    name: "Carlos Fleck",
    ocupation: "Pai do medalhista Martin",
  },
  {
    text: "À Canguru de Matemática, gratidão por fazerem um trabalho tão incentivador as crianças e jovens que se dedicam tanto, e que tem esse reconhecimento através da premiação da @cagurudematematica",
    name: "Brenda Pinheiro",
    ocupation: "Aluna",
  },
  {
    text: "Meu filho recebeu ontem! Voltou para casa mega feliz com a medalha brilhando no peito e o sorriso de orelha a orelha e meu coração ficou explodindo de orgulho. Não vemos a hora da próxima edição.",
    name: "Lauri Fernandes",
    ocupation: "Mãe do aluno Lucas",
  },
];

const DepoimentosCarousel = () => {
  const [activePage, setActivePage] = useState(0);

  const goToSlide = (index) => {
    setActivePage(index);
  };

  let slidesPerPage = 1;
  if (window.innerWidth >= 900 && window.innerWidth < 1200) {
    slidesPerPage = 2;
  } else if (window.innerWidth >= 1200) {
    slidesPerPage = 3;
  }

  const totalPages = Math.ceil(slideData.length / slidesPerPage);

  const paginationIndices = Array.from({ length: totalPages }, (_, index) => index);

  // eslint-disable-next-line
  const activeIndex = activePage * slidesPerPage;

  return (
    <div className="slider-container">
      <div className="slides">
        {slideData.map((slide, index) => {
          const pageIndex = Math.floor(index / slidesPerPage);
          return (
            <article key={index} className={`slide ${pageIndex === activePage ? "active" : ""}`}>
              <div className="slide-content">
                <div className="slide-icon">
                  <PiQuotesFill size={28} className="icon" />
                </div>
                <div className="slide-text">
                  <div className="slide-top">
                    <p>{slide.text}</p>
                  </div>
                  <div className="slide-bottom">
                    <h3>{slide.name}</h3>
                    <small>{slide.ocupation}</small>
                  </div>
                </div>
              </div>
            </article>
          );
        })}
      </div>
      <div className="pagination">
        {paginationIndices.map((index) => (
          <span
            key={index}
            className={`pagination-dot ${index === activePage ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default DepoimentosCarousel;
