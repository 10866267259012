import React from "react";
import "./footer-web-lg.css";
import LOGOUPMAT from "../../../assets/img/footer/logo-upmat-preto.svg";
import LOGOGARAGEM from "../../../assets/img/footer/logo-garagem.svg";
import CANGURU from "../../../assets/img/footer/canguru-cruzado.svg";

import {
  PiEnvelopeSimple,
  PiInstagramLogoFill,
  PiFacebookLogoFill,
  PiYoutubeLogoFill,
  PiCopyright,
} from "react-icons/pi";

const FooterWebLg = () => {
  return (
    <div className="footer-container">
      <div className="footer-left">
        <div className="footer-left-top">
          <div className="footer-realizacao-lg">
            <h3>Realização e Organização</h3>

            <div className="footer-logos-lg">
              <img src={LOGOUPMAT} alt="Upmat Educacional" />
              <img src={LOGOGARAGEM} alt="Garagem BNDES" />
            </div>

            <div className="footer-mail-lg">
              <PiEnvelopeSimple />
              <small>contato@cangurudematematicabrasil.com.br</small>
            </div>
          </div>

          <div className="footer-canguru-lg">
            <h3>Canguru de Matemática Brasil</h3>
            <div className="footer-links-lg">
              <ul>
                <li>
                  <a
                    href="https://experience.cangurudematematicabrasil.com.br"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <small>Canguru Experience</small>
                  </a>
                </li>
                <li>
                  <a
                    href="https://cangurudematematicabrasil.com.br/contecomamatematica"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <small>Conte com a matemática</small>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-conheca-lg">
            <h3>Conheça também</h3>
            <div className="footer-links">
              <ul>
                <li>
                  <a
                    href="https://nossaloja.upmat.com.br"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <small>Nossa Loja</small>
                  </a>
                </li>
                <li>
                  <a
                    href="https://bebrasbrasil.com.br"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <small>Bebras Brasil</small>
                  </a>
                </li>
                <li>
                  <a
                    href="https://desafio.bebrasbrasil.com.br"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <small>Desafio Bebras</small>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-acompanhe-lg">
            <h3>Acompanhe</h3>
            <div className="footer-icons">
              <a
                href="https://www.instagram.com/cangurudematematicabrasil/"
                target="_blank"
                rel="noreferrer"
              >
                <PiInstagramLogoFill size={28} />
              </a>

              <a
                href="https://www.facebook.com/cangurudematematicabrasil"
                target="_blank"
                rel="noreferrer"
              >
                <PiFacebookLogoFill size={28} />
              </a>

              <a
                href="https://www.youtube.com/c/CangurudeMatemáticaBrasiloficial"
                target="_blank"
                rel="noreferrer"
              >
                <PiYoutubeLogoFill size={28} />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-left-bootom">
          <div className="container copyright-container-lg">
            <PiCopyright style={{ marginTop: 4 }} />
            <small>
              UpMat Brasil Educacional Ltda | Todos os direitos reservados.
            </small>
          </div>
        </div>
      </div>

      <div className="footer-img-lg">
        <img src={CANGURU} alt="" />
      </div>
    </div>
  );
};

export default FooterWebLg;
