import React from "react";
import "./faq.css";
import Duvidas from "../../components/duvidas/Duvidas";

const Faq = () => {
  return (
    <>
      <div className="container faq-container" id="faq-home">
        <div className="faq-header">
          <p>
            <a href="/">Home</a> <font color="#141414">/</font> FAQ
          </p>
        </div>
        <div className="faq-content">
          <div className="faq-title">
            <p>Dúvidas</p>
            <h5>Perguntas Frequentes</h5>
          </div>

          <div className="faq-txt">
            <small>
              Se não encontrar respostas para suas perguntas na lista abaixo,
              entre em contato conosco por e-mail ou através das redes sociais.
            </small>
          </div>
        </div>
      </div>

      <Duvidas />
    </>
  );
};

export default Faq;
