import React, { useState } from "react";
import "./guia.css";

import CANGURU from "../../assets/img/guia/canguru-duvida.svg";
import ModalComponent from "./modal-component/ModalComponent";

const Guia = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <section id="guia">
      <div className="container guia-container">
        <div className="guia-img">
          <img src={CANGURU} alt="Canguru com dúvida" />
        </div>
        <div className="guia-txt">
          <p>Dúvidas sobre a inscrição no Concurso?</p>
          <small>
            Faça o download do nosso guia passo a passo e simplifique o processo
            de inscrição.
          </small>
          <div className="btn btn-guia">
            <p onClick={handleButtonClick}>Clique aqui</p>
          </div>
        </div>
      </div>
      <ModalComponent isOpen={modalOpen} closeModal={closeModal} />
    </section>
  );
};

export default Guia;
