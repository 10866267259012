import React from "react";
import "./comunidade-top.css";
import MAPAEMASCOTE from "../../../assets/img/comunidade/mapa-mascote.svg";

const ComunidadeTop = () => {
  return (
    <section id="comunidade-top">
      <div className="container comunidade-container">
        <div className="comunidade-txt">
          <h4>Comunidade Internacional</h4>
          <p>
            Somos parte da comunidade internacional Kangourou sans Frontières,
            presente em mais de 95 países, com 30 anos desde sua fundação.
            Anualmente, professores do mundo todo se reúnem para trazer o que há
            de mais moderno no ensino da matemática mundial e preparar as
            provas.
          </p>
        </div>

        <div className="comunidade-img">
          <img src={MAPAEMASCOTE} alt="" />
        </div>
      </div>
    </section>
  );
};

export default ComunidadeTop;
