import React from "react";
import "./regulamentos.css";
import Regulamento from "../../components/regulamentos/regulamento/Regulamento";

const Regulamentos = () => {
  return (
    <>
      <div className="container regulamento-container" id="regulamento-home">
        <div className="regulamento-header">
          <p>
            <a href="/">Home</a> <font color="#141414">/</font> Regulamentos
          </p>
        </div>
        <div className="regulamento-content">
          <div className="regulamento-title">
            <p>Concurso Canguru</p>
            <h5>Regulamento</h5>
          </div>
        </div>

        <Regulamento />
      </div>
    </>
  );
};

export default Regulamentos;
