import React from "react";
import Programatico from "../../components/regulamentos/programatico/Programatico";

const ConteudoProgramatico = () => {
  return (
    <div className="container regulamento-container" id="regulamento-home">
      <div className="regulamento-header">
        <p>
          <a href="/">Home</a> <font color="#141414">/</font>{" "}
          <a href="/regulamento">Regulamentos</a> <font color="#141414">/</font>{" "}
          Conteúdo Programático
        </p>
      </div>

      <Programatico />
    </div>
  );
};

export default ConteudoProgramatico;
