import React, { useEffect, useState } from "react";
import "./footer.css";
import FooterMobile from "./footer-mobile/FooterMobile";
import FooterWeb from "./footer-web/FooterWeb";
import FooterWebLg from "./footer-web-lg/FooterWebLg";

const Footer = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer id="footer">
      {windowSize < 900 && <FooterMobile />}
      {windowSize >= 900 && windowSize < 1200 && <FooterWeb />}
      {windowSize >= 1200 && <FooterWebLg />}
    </footer>
  );
};

export default Footer;
