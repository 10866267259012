import React, { useState } from "react";
import "./inscricoes.css";
import { PiCheckFatFill } from "react-icons/pi";
import LINHAPERSONALIZADO from "../../assets/img/inscricoes/linha-personalizado.svg";
import BG from "../../assets/img/inscricoes/bg.svg";

const Tab = ({ label, activeTab, onClick }) => {
  const handleClick = () => {
    onClick(label);
  };

  return (
    <div
      className={`tab ${activeTab === label ? "active" : ""}`}
      onClick={handleClick}
    >
      {label}
    </div>
  );
};

// --- VALORES COM DESCONTO ---
const TabContent = ({ activeTab }) => {
  return (
    <div className="tab-content">
      <div className="cards-inscricoes">
        <div className="card-padrao">
          <div className="semi-circle-padrao"></div>
          <div className="card-padrao-content">
            <div className="padrao-top-txt">
              <h4>
                Concurso <font color="#231f20">Canguru</font>
              </h4>
              <small>Padrão</small>
              <p>
                Desperte o potencial de seus
                <br />
                alunos com a matemática!
              </p>
            </div>
            <div className="padrao-mid-txt">
              <div className="padrao-item1">
                <PiCheckFatFill size={14} color="#009ddc" />
                <p>
                  <b>Participação garantida</b> no Concurso Canguru,
                  <br />
                  <b>conforme Regulamento</b>.
                </p>
              </div>
              <div className="padrao-item2">
                <PiCheckFatFill size={14} color="#009ddc" />
                <p>
                  <b>Certificação</b> digital para estudantes e professores.
                </p>
              </div>
              <div className="padrao-item2">
                <PiCheckFatFill size={14} color="#009ddc" />
                <p>
                  <b>Relatório de Desempenho da Escola:</b> Compare o desempenho
                  médio da sua escola com a média nacional, por nível de prova,
                  dificuldade e eixos.
                </p>
              </div>
              <div className="padrao-item2">
                <PiCheckFatFill size={14} color="#009ddc" />
                <p>
                  <b>Nova Área do Estudante:</b> Com quiz exclusivo e uma trilha
                  de vídeos com resoluções detalhadas das provas anteriores!
                </p>
              </div>
              <div className="padrao-item2">
                <PiCheckFatFill size={14} color="#009ddc" />
                <p>
                  <b>Valor único por escola:</b> Cadastre quantos alunos
                  desejar, sem custo adicional por participante.
                </p>
              </div>
              <div className="padrao-item2">
                <PiCheckFatFill size={14} color="#009ddc" />
                <p>
                  <b>Medalhas (item opcional):</b> Escolha adquirir as medalhas
                  como reconhecimento extra para seus alunos.
                </p>
              </div>
            </div>
            <div className="padrao-low-txt">
              <div className="valor-padrao-solo">
                <div className="destaque-valor-padrao-solo">
                  {/* <p>Valor</p>
                  {activeTab === "Escolas Públicas" && <p>R$149,00</p>}
                  {activeTab === "Escolas Privadas" && <p>R$399,00</p>} */}
                </div>
              </div>
            </div>
            <div className="btn btn-card-padrao">
              <p>Em Breve</p>
              {/* <a
                href="https://gestao.upmat.com.br/sales/kangaroo"
                target="_blank"
                rel="noreferrer"
              >
                Inscrever-se
              </a> */}
            </div>
          </div>
        </div>

        <div className="card-personalizado">
          <div className="semi-circle-personalizado"></div>
          <div className="card-personalizado-content">
            <div className="personalizado-top-txt">
              <h4>
                Concurso <font color="#231f20">Canguru</font>
              </h4>
              <small>Personalizado</small>
              <p>
                Personalize a experiência e eleve o
                <br />
                nível do aprendizado matemático!
              </p>
            </div>
            <div className="personalizado-mid-txt">
              <div className="personalizado-item0">
                <div className="item0-txt">
                  <PiCheckFatFill size={14} color="#49A942" />
                  <p>
                    Tudo o que o Concurso Canguru
                    <br />
                    Padrão oferece
                  </p>
                </div>
                <div className="item0-img">
                  <img src={LINHAPERSONALIZADO} alt="" id="linha" />
                </div>
              </div>
              <div className="personalizado-item2">
                <PiCheckFatFill size={14} color="#49A942" />
                <p>
                  <b>Placa de Participação: </b>
                  Um símbolo de reconhecimento especial para sua escola.
                </p>
              </div>
              <div className="personalizado-item2">
                <PiCheckFatFill size={14} color="#49A942" />
                <p>
                  <b>Revista Canguru do Ano: </b>
                  Conteúdo exclusivo com questões da prova e resoluções
                  comentadas.
                </p>
              </div>
              <div className="personalizado-item2">
                <PiCheckFatFill size={14} color="#49A942" />
                <p>
                  <b>Relatório de Desempenho dos Estudantes: </b>
                  Acompanhe o desempenho individual e coletivo com relatórios
                  detalhados.
                </p>
              </div>
              <div className="personalizado-item2">
                <PiCheckFatFill size={14} color="#49A942" />
                <p>
                  <b>Nova Área do Estudante: </b>
                  Agora com quiz exclusivo, com resultados visualizados pela
                  escola, e uma trilha completa de vídeos com resolução
                  detalhada das provas anteriores!
                </p>
              </div>
              <div className="personalizado-item2">
                <PiCheckFatFill size={14} color="#49A942" />
                <p>
                  <b>Valor único por escola: </b>
                  Cadastre quantos alunos desejar, oferecendo a eles uma
                  experiência completa e enriquecedora.
                </p>
              </div>
              <div className="personalizado-item2">
                <PiCheckFatFill size={14} color="#49A942" />
                <p>
                  <b>Medalhas (item opcional): </b>
                  Disponíveis para compra como um incentivo extra aos alunos.
                </p>
              </div>
            </div>
            <div className="personalizado-low-txt">
              <div className="valor-personalizado-solo">
                <div className="destaque-valor-personalizado-solo">
                  {/* <p>Valor</p>
                  {activeTab === "Escolas Públicas" && <p>R$343,00</p>}
                  {activeTab === "Escolas Privadas" && <p>R$787,00</p>} */}
                </div>
              </div>
            </div>
            <div className="btn btn-card-personalizado">
              <p>Em Breve</p>
              {/* <a
                href="https://gestao.upmat.com.br/sales/kangaroo"
                target="_blank"
                rel="noreferrer"
              >
                Inscrever-se
              </a> */}
            </div>
          </div>
        </div>

        <div className="separador">
          <p>+</p>
        </div>

        <div className="card-combo">
          <div className="semi-circle-combo"></div>
          <div className="card-combo-content">
            <div className="combo-top-txt">
              <h4>
                Plataforma
                <br />
                Canguru Experience
              </h4>
              <p>
                Canguru de Matemática na
                <br />
                Sala de Aula o ano todo!
              </p>
            </div>
            <div className="combo-mid-txt">
              <div className="combo-item0">
                <div className="combo-item0-main">
                  <p>
                    Transforme o aprendizado:
                    <br />
                    professores mais preparados,
                    <br />
                    estudantes mais motivados!
                  </p>
                </div>
                <div className="combo-item0-txt">
                  <PiCheckFatFill size={14} color="#642EE4" />
                  <p>
                    Tudo o que o Concurso Canguru
                    <br />
                    Padrão oferece
                  </p>
                </div>
              </div>
              <div className="combo-item2">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>
                  <b>Trilha de Preparação</b> para o Concurso Canguru.
                </p>
              </div>
              <div className="combo-item2">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>
                  <b>KangooTreino: </b>atividades exclusivas para praticar os
                  conhecimentos para o Concurso Canguru.
                </p>
              </div>
              <div className="combo-item2">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>
                  <b>Atividades de Sondagem</b> para identificar pontos fortes e
                  áreas de melhoria.
                </p>
              </div>
              <div className="combo-item2">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>
                  <b>Aula Canguru: </b>roteiros exclusivos de aulas.
                </p>
              </div>
              <div className="combo-item2">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>
                  <b>Acervo Completo: </b>todo o conteúdo do Concurso Canguru.
                </p>
              </div>
              <div className="combo-item2">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>
                  <b>Formação Continuada</b> para professores, com temas
                  matemáticos atuais.
                </p>
              </div>
              <div className="combo-item2">
                <PiCheckFatFill size={14} color="#642EE4" />
                <p>
                  <b>Acesso</b> para até 10 Professores.
                </p>
              </div>
            </div>
            <div className="combo-low-txt">
              <div className="combo-valor-padrao">
                <p>Acrescente o Canguru EXP ao seu pacote</p>
                <div className="destaque-valor-padrao">
                  {/* <p>Valor</p>
                  {activeTab === "Escolas Públicas" && <p>R$600,00</p>}
                  {activeTab === "Escolas Privadas" && <p>R$1200,00</p>} */}
                </div>
              </div>
            </div>
            {/* <div className="combo-desconto-destaque">
              <p>
                <font size="5">*40%</font> de desconto para novas escolas que se
                inscreverem no Concurso Canguru.
                <br />
                Oferta por tempo limitado!
              </p>
            </div>
            <div className="combo-desconto-low">
              <p>
                *Para as escolas que já fazem parte do Canguru Experience desde
                2023, a renovação está disponível com um desconto garantido de
                50%.
              </p>
            </div> */}
            <div className="btn btn-card-combo">
              <a
                href="https://experience.cangurudematematicabrasil.com.br"
                target="_blank"
                rel="noreferrer"
              >
                Saiba mais
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Inscricoes = () => {
  const [activeTab, setActiveTab] = useState("Escolas Públicas");

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  return (
    <section id="inscricoes">
      <div className="container inscricoes-container">
        <div className="inscricoes-bg">
          <img src={BG} alt="" />
        </div>
        <div className="inscricoes-header">
          <h4>Inscrições</h4>
        </div>

        <div className="tabs">
          <Tab
            label="Escolas Públicas"
            activeTab={activeTab}
            onClick={handleTabClick}
            id="publicas"
          />
          <Tab
            label="Escolas Privadas"
            activeTab={activeTab}
            onClick={handleTabClick}
            id="privadas"
          />
        </div>
        <TabContent activeTab={activeTab} />
      </div>
    </section>
  );
};

export default Inscricoes;
